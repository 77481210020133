<template>
  <div>
    <canvas ref="chart"></canvas>
  </div>
</template>

<script>

import Default from '@/components/charts/Default.vue';
export default {
    mixins: [Default],
    data() {
        return {
            chart: null,
            type:'line',
            data: {}
        }
    }
};
</script>
