<template>
  <div>
    <canvas ref="chart"></canvas>
  </div>
</template>

<script>
import { ref } from 'vue';
import Chart from 'chart.js/auto';
export default {
    data() {
        return {
            chart: null,
            type: 'bar'
        }
    },
    mounted() {
        this.getSummary();
    },
    methods: {
        getSummary() {
            return this.$axios.get('/_/summary/count')
            .then(response => response.data)
            .then(response => {
                this.chart = new Chart(this.$refs.chart, {
                    type: this.type,
                    data: response
                });
            })
            .catch(err => { throw err });
        }
    }
};
</script>
