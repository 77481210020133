<template>
    <Sales />
</template>

<script>
import Sales from '@/components/charts/Sales.vue';

export default {
  components: {
    Sales,
  },
};
</script>